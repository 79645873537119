// Here you can add other styles
$biru: #39f;
$hijau: #00a4a6;
$subtitleColor: #787a91;

.background-login {
  background: url('./../views/pages/login/background-masagi2.jpg') no-repeat center center;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}

.card-container {
  height: 100%;
  padding: 100px 10px 100px 10px;

  .row {
    height: 100%;
  }
}

.logo-cadisdik {
  border-radius: 50%;
  border: 5px solid #2aafe5;
  margin-bottom: 10px;
}

.login-left-content {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-image: linear-gradient(to top, #4ac7ee, #38b0dd, #2999cb, #1e82b8, #186ca4);
  // background-image: linear-gradient(to bottom, #0d8647, #00947a, #009fad, #00a6d8, #03a9f4);
  text-align: center;
  padding: 100px;

  .login-left-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    // margin-top: 20px;
    margin-bottom: 10px;
  }

  .login-left-subtitle {
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
  }
}

.login-right-content {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #fff;
  // padding: 50px;
  text-align: center;

  .login-right-title {
    letter-spacing: 0.7px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .login-right-subtitle {
    margin-top: 10px;
    letter-spacing: 0.5px;
    color: #8d8d8d;
    margin-bottom: 50px;
  }
}

.login-button {
  background-color: #4ac7ee;
  border: none;
  border-radius: 15px;
  padding: 10px
}

.login-button:hover {
  background-color: #186ca4;
}

.custom-file-label {
  z-index: 0 !important;
}

.login-title {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 50px;
}

.login-form-control {
  border-radius: 15px;
  padding: 20px
}

.css-yk16xz-control {
  border-radius: 15px !important;
  padding: 3px
}

.background-img {
  /* border-right: 1px solid #eeeeee; */
}

.login-line {
  margin-bottom: 30px;
}

.img-fluid {
  border-right: 1px;
}

.logo-disdik {
  width: 80px;
}

.logo-tikom {
  width: 120px;
}

a {
  color: $biru;
}

a:hover {
  color: $biru;
}

a.link-prev {
  color: #3c4b64;
}

a.link-prev:hover {
  text-decoration: none;
}

.container-fluid {
  padding-right: 200px !important;
  padding-left: 200px !important;
}

.card-mutasi {
  border: none;
  border-radius: 15px;
  height: 100%;
}

.card-body-mutasi {
  padding: 50px;

  .title {
    font-weight: bold;
    letter-spacing: 0.5px;
  }

  .subtitle {
    letter-spacing: 0.5px;
    font-weight: 500;
    color: $subtitleColor;
  }
}

.card-mutasiv2 {
  border: none;
  border-radius: 15px;
}

.modal-content {
  border: none;
  border-radius: 15px;

  .modal-body {
    padding: 30px;

    h2 {
      font-weight: bold;
      letter-spacing: 0.5px;
      margin-bottom: 20px;
    }

    label {
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
}

.c-sidebar {
  color: #fff;
  background: #3f4a59;
}

.c-sidebar-nav-link {
  font-weight: 500;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #00a4a6;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  border: 1px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  color: $biru;
  background-color: #fff;
  border-color: #d8dbe0;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: $biru;
  border-color: $biru;
}

.page-link:hover {
  z-index: 2;
  text-decoration: none;
  color: $biru;
  background-color: #d8dbe0;
  border-color: #c4c9d0;
}

.border-keterangan {
  border: 3px solid #3f4a59;
  padding: 5px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  // box-shadow: 10px 10px;
}

.react-date-picker {
  display: flex !important;
}

.card-dashboard {
  padding: 20px;
  border: 0;
  border-radius: 5px;
  /* text-transform: uppercase; */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.stat-content h2 {
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #fff;
  margin: auto;
}

.stat-content h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #fff;
  margin: auto;
}

// h2 {
//   font-weight: 700;
//   letter-spacing: 0.5px;
//   color: #fff;
//   margin: auto;
// }

.grafik h5 {
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000;
  margin: 0;
}

/* h3{
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 20px;
} */

.nav-item .nav-link {
  color: #007bff;
  font-size: 15px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000 !important;
  background-color: #fff;
  border-color: #c4c9d0 #c4c9d0 #fff;
}

.table-jurusan thead th {
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
}

.table-jurusan tbody td {
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
}

.leaflet-container {
  width: 100%;
  height: 60vh;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc::before {
  content: "\2191";
  position: absolute;
  right: 5px;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc::before {
  content: "\2193";
  position: absolute;
  right: 5px;
}

.activity-item {
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  margin: 10px;
}

.activity-info {
  color: #000;
}

.activity-info .user-name {
  font-size: 18px;
  font-weight: bold;
}

.activity-info .user-email {
  color: #666;
}

.activity-info .activity-time {
  font-weight: bold;
  color: #666;
  float: right;
}

.activity-title {
  color: #666;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}

.activity-body {
  margin-bottom: 10px;
}